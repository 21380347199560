import React from "react";
import moment from "moment";
import ModalImage from "react-modal-image";
import { deleteMessage } from "../../../../../korper-redux/actions";
import { connect } from "react-redux";
import axios from "axios";
import { CircularProgress } from "@material-ui/core";
import "./Message.css";

function Message(props) {
  const { data, isMine, startsSequence, endsSequence, showTimestamp } = props;

  const [summary, setSummary] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const onSummarize = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `https://online.korper.io/coachs/message/summarize`,
        {
          message: data.text,
          firstName: data.user.name.split(" ")[0],
        },
        { withCredentials: true }
      );

      setSummary(response.data.summary);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const friendlyTimestamp = moment(data.createdAt).format("LLLL");
  const mediaOnly = (data.image || data.video) && !data.text;

  const onBubbleClicked = (e) => {
    // console.log('Click')
    if (e.shiftKey) {
      // console.log('Deleting', data._id)
      try {
        axios.delete(`https://online.korper.io/coachs/messages/${data._id}`, {
          withCredentials: true,
        });

        props.deleteMessage(data._id);
      } catch (err) {
        console.error(err);
      }
    }
  };

  return (
    <div
      className={[
        "message",
        `${isMine ? "mine" : ""}`,
        `${startsSequence ? "start" : ""}`,
        `${endsSequence ? "end" : ""}`,
      ].join(" ")}
    >
      {showTimestamp && <div className="timestamp">{friendlyTimestamp}</div>}

      <div className="bubble-container">
        <div
          className="bubble"
          title={friendlyTimestamp}
          style={{ padding: mediaOnly ? 0 : undefined }}
          onClick={onBubbleClicked}
        >
          {!isMine && (
            <div
              className="summarize-button"
              style={{
                position: "absolute",
                top: "-10px",
                right: "-10px",
                opacity: summary ? 1 : undefined,
              }}
            >
              <button
                style={{
                  backgroundColor: "#419dff",
                  border: "none",
                  color: "white",
                  width: "28px",
                  height: "28px",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
                onClick={summary ? () => setSummary(null) : onSummarize}
              >
                {loading ? (
                  <span>
                    <b>...</b>
                  </span>
                ) : summary ? (
                  <img
                    src="https://static.thenounproject.com/png/1226950-200.png"
                    alt="Revert"
                    width="18"
                    height="18"
                  />
                ) : (
                  <img
                    src="https://static.thenounproject.com/png/6056254-200.png"
                    alt="Summarize"
                    width="18"
                    height="18"
                  />
                )}
              </button>
            </div>
          )}
          {data.image && (
            <ModalImage
              small={data.image}
              large={data.image}
              hideDownload
              className="image-preview"
            />
          )}
          {data.video && (
            <video className="video-preview" controls>
              <source src={data.video} />
            </video>
          )}
          {data.text && summary && <span>{summary}</span>}
          {data.text && !summary && <span>{data.text}</span>}
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = { deleteMessage };

export default connect(null, mapDispatchToProps)(Message);
