import React, { useEffect, useState, useMemo } from "react";
import "react-circular-progressbar/dist/styles.css";
import { Avatar } from "@material-ui/core";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import MultiProgress from "react-multi-progress";
import "./Monitoring.css";
import moment from "moment";
import { connect } from "react-redux";
import getInitials from "../../../../../utils/getInitials";

moment.updateLocale("fr", {
  relativeTime: {
    ss: "%d sec",
    mm: "%d min",
    hh: "%dh",
  },
});

function Monitoring(props) {
  const statsTraining = () => {
    let user = {
      training: {
        done: props.students.filter(
          (customer) => customer.stats.lastWeek.trainingDone > 0
        ),
        missed: props.students.filter(
          (customer) => customer.stats.lastWeek.trainingMissed > 0
        ),
      },
      activity: {
        growth: props.students.filter(
          (customer) => customer.stats.lastWeek.evolution === "growth"
        ),
        decline: props.students.filter(
          (customer) => customer.stats.lastWeek.evolution === "decline"
        ),
      },
    };

    const data = {
      daily: {
        done: props.students.filter((customer) =>
          moment(customer.isTrainingDone).isAfter(moment().set("hour", 3))
        ),
        todo: props.students.filter(
          (customer) =>
            !moment().isSame(moment(customer.isTrainingDone), "day") &&
            customer.hasTraining === true
        ),
        none: props.students.filter(
          (customer) => customer.hasTraining === false
        ),
      },
      weekly: {
        done: {
          total: user.training.done.reduce(
            (acc, curr) => acc + curr.stats.lastWeek.trainingDone,
            0
          ),
          customerlist: user.training.done.sort(
            (a, b) =>
              b.stats.lastWeek.trainingDone - a.stats.lastWeek.trainingDone
          ),
        },
        missed: {
          total: user.training.missed.reduce(
            (acc, curr) => acc + curr.stats.lastWeek.trainingMissed,
            0
          ),
          customerlist: user.training.missed.sort(
            (a, b) =>
              b.stats.lastWeek.trainingMissed - a.stats.lastWeek.trainingMissed
          ),
        },
      },
      activity: {
        growth: props.students.filter(
          (customer) => customer.stats.lastWeek.evolution === "growth"
        ),
        decline: props.students.filter(
          (customer) => customer.stats.lastWeek.evolution === "decline"
        ),
        total: {
          done: user.activity.growth.reduce(
            (acc, curr) => acc + curr.stats.lastWeek.trainingDone,
            0
          ),
          missed: user.activity.decline.reduce(
            (acc, curr) => acc + curr.stats.lastWeek.trainingMissed,
            0
          ),
        },
      },
    };

    return data;
  };
  const data = useMemo(statsTraining, [props.students]);

  const [activeTab, setActiveTab] = useState(0);
  const [activeVerticalTab, setActiveVerticalTab] = useState(0);
  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  const handleVerticalTabClick = (index) => {
    setActiveVerticalTab(index);
  };
  const lastTrainingDoneTime = (customer) => {
    if (moment(customer.isTrainingDone).fromNow() === "Invalid date")
      return "Il y a 1 sec";
    else if (data.daily.done) return moment(customer.isTrainingDone).fromNow();
  };

  useEffect(() => {
    const elements = document.querySelectorAll(".progressbar, .progressbar *");
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      element.style.zIndex = 2;
    }
  }, []);
  const progressBarValue = () => {
    let total = data.daily.done.length + data.daily.todo.length;
    const value = {
      done: (data.daily.done.length / total) * 100,
      todo: (data.daily.todo.length / total) * 100,
    };
    return value;
  };

  return (
    <div style={{ boxSizing: "border-box" }}>
      <div style={{ display: "flex", alignItems: "baseline" }}>
        <h2
          style={{
            fontSize: "26px",
            color: "black",
            margin: "20px 35px 0px 35px",
          }}
        >
          Bonjour {props.coachFirstName}
        </h2>
      </div>
      <div className="mainContainer">
        <div className="rowWrapper">
          <div className="boxSpacer col-70">
            <div className="box " style={{ height: "90%" }}>
              <table id="girddata">
                <tr>
                  <td></td>
                  <td>Clients</td>
                  <td>Entraînements complétés</td>
                  <td>Temps de réponse / message</td>
                </tr>
                <tr>
                  <td>Cette semaine</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0min</td>
                </tr>
                <tr>
                  <td>Semaine dernière</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0min</td>
                </tr>
              </table>
            </div>
          </div>
          <div className="boxSpacer col-30">
            <div className="box " style={{ height: "90%" }}>
              Semaine prochaine
              <div
                className="flex"
                style={{ paddingTop: "15px", justifyContent: "center" }}
              >
                <div style={{ width: 120, height: 120 }}>
                  <CircularProgressbar
                    styles={buildStyles({ strokeLinecap: "butt" })}
                    strokeWidth={10}
                    value={0.0}
                    maxValue={1}
                    text={`${0.0 * 100}%`}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "12px",
                  fontWeight: "500",
                  paddingTop: "10px",
                  color: "#8B8B8B",
                }}
              >
                À PROGRAMMER
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "baseline" }}>
          <h2
            style={{
              fontWeight: "500",
              color: "black",
              margin: "20px 35px 25px 0px",
            }}
          >
            Entraînement
          </h2>
        </div>
        <div className="rowWrapper">
          <div className="boxSpacer col-50">
            <div
              className="box"
              style={{
                paddingLeft: "0px",
                paddingRight: "0px",
                paddingBottom: "0px",
              }}
            >
              <p className="pl-15">Aujourd'hui</p>
              <p
                className="flex pl-15"
                style={{ marginTop: "15px", alignItems: "baseline" }}
              >
                <h4>
                  {data.daily.todo.length + data.daily.done.length} clients{" "}
                </h4>
                &nbsp;ont un entraînement de prévu aujourd'hui.
              </p>
              <div className="flex pl-15" style={{ paddingTop: "15px" }}>
                <div style={{ width: "95%" }}>
                  {/* faire les regle de calcule / ratio des value pour rester en % */}
                  <MultiProgress
                    className="progressbar"
                    transitionTime={1.2}
                    elements={[
                      {
                        value: progressBarValue().done,
                        color: "#00FFFF",
                        isBold: false,
                      },
                      {
                        value: progressBarValue().todo,
                        color: "#d6d6d6",
                        fontSize: 12,
                        textColor: "gray",
                        isBold: true,
                      },
                    ]}
                    height={30}
                  />
                </div>
              </div>
              <div className="tabs" style={{ marginTop: "30px" }}>
                <ul className="tabs-nav">
                  <li className={activeTab === 0 ? "tab-active" : ""}>
                    <a onClick={() => handleTabClick(0)}>
                      Complétés
                      <h3>{data.daily.done.length}</h3>
                    </a>
                  </li>
                  <li className={activeTab === 1 ? "tab-active" : ""}>
                    <a onClick={() => handleTabClick(1)}>
                      À faire<h3>{data.daily.todo.length}</h3>
                    </a>
                  </li>
                  <li className={activeTab === 2 ? "tab-active" : ""}>
                    <a onClick={() => handleTabClick(2)}>
                      Repos
                      <h3>{data.daily.none.length}</h3>
                    </a>
                  </li>
                </ul>
                <div className="tabs-stage">
                  {activeTab === 0 && (
                    <div id="tab-1" className="tab-content">
                      <div class="list" id="style-1">
                        <ul>
                          {data.daily.done.map((customer, i) => {
                            return (
                              <li key={i}>
                                <div
                                  onClick={() =>
                                    props.onCustomerSelect(customer)
                                  }
                                  className="user-wrapper"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <li>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        marginLeft: "30px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          margin: "7px 0px",
                                          padding: "5px",
                                        }}
                                      >
                                        <Avatar
                                          src={customer.profilePicture}
                                          style={{
                                            backgroundColor: "#bdbdbd",
                                            borderRadius: "100%",
                                            width: "30px",
                                            height: "30px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            fontSize: "12px",
                                            color: "white",
                                          }}
                                        >
                                          {getInitials(
                                            `${customer.firstName} ${customer.lastName}`
                                          )}
                                        </Avatar>
                                        <div
                                          style={{
                                            marginLeft: "20px",
                                            fontSize: "13px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {customer.firstName}{" "}
                                          {customer.lastName}
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <div>
                                    <div style={{ color: "#848484" }}>
                                      {lastTrainingDoneTime(customer)}
                                    </div>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  )}
                  {activeTab === 1 && (
                    <div id="tab-2" className="tab-content">
                      <div class="list" id="style-1">
                        <ul>
                          {data.daily.todo.map((customer, i) => {
                            return (
                              <li key={i}>
                                <div
                                  onClick={() =>
                                    props.onCustomerSelect(customer)
                                  }
                                  className="user-wrapper"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <li>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        marginLeft: "30px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          margin: "7px 0px",
                                          padding: "5px",
                                        }}
                                      >
                                        <Avatar
                                          src={customer.profilePicture}
                                          style={{
                                            backgroundColor: "#bdbdbd",
                                            borderRadius: "100%",
                                            width: "30px",
                                            height: "30px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            fontSize: "12px",
                                            color: "white",
                                          }}
                                        >
                                          {getInitials(
                                            `${customer.firstName} ${customer.lastName}`
                                          )}
                                        </Avatar>
                                        <div
                                          style={{
                                            marginLeft: "20px",
                                            fontSize: "13px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {customer.firstName}{" "}
                                          {customer.lastName}
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <div>
                                    <div style={{ color: "#848484" }}></div>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  )}
                  {activeTab === 2 && (
                    <div id="tab-3" className="tab-content">
                      <div class="list" id="style-1">
                        <ul>
                          {data.daily.none.map((customer, i) => {
                            return (
                              <li key={i}>
                                <div
                                  onClick={() =>
                                    props.onCustomerSelect(customer)
                                  }
                                  className="user-wrapper"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <li>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        marginLeft: "30px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          margin: "7px 0px",
                                          padding: "5px",
                                        }}
                                      >
                                        <Avatar
                                          src={customer.profilePicture}
                                          style={{
                                            backgroundColor: "#bdbdbd",
                                            borderRadius: "100%",
                                            width: "30px",
                                            height: "30px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            fontSize: "12px",
                                            color: "white",
                                          }}
                                        >
                                          {getInitials(
                                            `${customer.firstName} ${customer.lastName}`
                                          )}
                                        </Avatar>
                                        <div
                                          style={{
                                            marginLeft: "20px",
                                            fontSize: "13px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {customer.firstName}{" "}
                                          {customer.lastName}
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <div>
                                    <div style={{ color: "#848484" }}></div>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="boxSpacer col-50">
            <div
              className="box"
              style={{
                paddingLeft: "0px",
                paddingRight: "0px",
                paddingBottom: "0px",
              }}
            >
              <p className="pl-15">7 derniers jours</p>
              <div className="flex" style={{ maxWidth: "100%", width: "100%" }}>
                <div
                  className="tabs vertical"
                  style={{ marginTop: "15px", display: "flex" }}
                >
                  <ul
                    className="tabs-nav"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <li className={activeVerticalTab === 0 ? "tab-active" : ""}>
                      <a onClick={() => handleVerticalTabClick(0)}>
                        Entraînements Complétés
                        <h3> {data.weekly.done.total}</h3>
                      </a>
                    </li>
                    <li className={activeVerticalTab === 1 ? "tab-active" : ""}>
                      <a onClick={() => handleVerticalTabClick(1)}>
                        Entraînements Manqués
                        <h3>{data.weekly.missed.total}</h3>
                      </a>
                    </li>
                    <li className={activeVerticalTab === 2 ? "tab-active" : ""}>
                      <a onClick={() => handleVerticalTabClick(2)}>
                        Augmentation d'activité
                        <h3>{data.activity.growth.length}</h3>
                      </a>
                    </li>
                    <li className={activeVerticalTab === 3 ? "tab-active" : ""}>
                      <a onClick={() => handleVerticalTabClick(3)}>
                        Baisse d'activité
                        <h3>{data.activity.decline.length}</h3>
                      </a>
                    </li>
                  </ul>
                  <div className="tabs-stage">
                    {activeVerticalTab === 0 && (
                      <div id="tab-1" className="tab-content">
                        <div class="list" id="style-1">
                          <ul>
                            {data.weekly.done.customerlist
                              .sort((a, b) =>
                                a.firstName.localeCompare(b.firstName)
                              )
                              .map((customer, i) => {
                                return (
                                  <li key={i}>
                                    <div
                                      onClick={() =>
                                        props.onCustomerSelect(customer)
                                      }
                                      className="user-wrapper"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <li>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            marginLeft: "30px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              margin: "7px 0px",
                                              padding: "5px",
                                            }}
                                          >
                                            <Avatar
                                              src={customer.profilePicture}
                                              style={{
                                                backgroundColor: "#bdbdbd",
                                                borderRadius: "100%",
                                                width: "30px",
                                                height: "30px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                fontSize: "12px",
                                                color: "white",
                                              }}
                                            >
                                              {getInitials(
                                                `${customer.firstName} ${customer.lastName}`
                                              )}
                                            </Avatar>
                                            <div
                                              style={{
                                                marginLeft: "20px",
                                                fontSize: "13px",
                                                fontWeight: "500",
                                              }}
                                            >
                                              {customer.firstName}{" "}
                                              {customer.lastName}
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <div>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            background: "#F7F7F7",
                                            borderRadius: "3px",
                                            width: "25px",
                                            height: "25px",
                                          }}
                                        >
                                          {customer.stats.lastWeek.trainingDone}
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      </div>
                    )}
                    {activeVerticalTab === 1 && (
                      <div id="tab-2" className="tab-content">
                        <div class="list" id="style-1">
                          <ul>
                            {data.weekly.missed.customerlist
                              .sort((a, b) =>
                                a.firstName.localeCompare(b.firstName)
                              )
                              .map((customer, i) => {
                                return (
                                  <li key={i}>
                                    <div
                                      onClick={() =>
                                        props.onCustomerSelect(customer)
                                      }
                                      className="user-wrapper"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <li>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            marginLeft: "30px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              margin: "7px 0px",
                                              padding: "5px",
                                            }}
                                          >
                                            <Avatar
                                              src={customer.profilePicture}
                                              style={{
                                                backgroundColor: "#bdbdbd",
                                                borderRadius: "100%",
                                                width: "30px",
                                                height: "30px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                fontSize: "12px",
                                                color: "white",
                                              }}
                                            >
                                              {getInitials(
                                                `${customer.firstName} ${customer.lastName}`
                                              )}
                                            </Avatar>
                                            <div
                                              style={{
                                                marginLeft: "20px",
                                                fontSize: "13px",
                                                fontWeight: "500",
                                              }}
                                            >
                                              {customer.firstName}{" "}
                                              {customer.lastName}
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <div>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            background: "#F7F7F7",
                                            borderRadius: "3px",
                                            width: "25px",
                                            height: "25px",
                                          }}
                                        >
                                          {
                                            customer.stats.lastWeek
                                              .trainingMissed
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      </div>
                    )}
                    {activeVerticalTab === 2 && (
                      <div id="tab-3" className="tab-content">
                        <div class="list" id="style-1">
                          <ul>
                            {data.activity.growth
                              .sort((a, b) =>
                                a.firstName.localeCompare(b.firstName)
                              )
                              .map((customer, i) => {
                                return (
                                  <li key={i}>
                                    <div
                                      onClick={() =>
                                        props.onCustomerSelect(customer)
                                      }
                                      className="user-wrapper"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <li>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            marginLeft: "30px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              margin: "7px 0px",
                                              padding: "5px",
                                            }}
                                          >
                                            <Avatar
                                              src={customer.profilePicture}
                                              style={{
                                                backgroundColor: "#bdbdbd",
                                                borderRadius: "100%",
                                                width: "30px",
                                                height: "30px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                fontSize: "12px",
                                                color: "white",
                                              }}
                                            >
                                              {getInitials(
                                                `${customer.firstName} ${customer.lastName}`
                                              )}
                                            </Avatar>
                                            <div
                                              style={{
                                                marginLeft: "20px",
                                                fontSize: "13px",
                                                fontWeight: "500",
                                              }}
                                            >
                                              {customer.firstName}{" "}
                                              {customer.lastName}
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <div>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            background: "#F7F7F7",
                                            borderRadius: "3px",
                                            width: "25px",
                                            height: "25px",
                                          }}
                                        >
                                          +
                                          {customer.stats.lastWeek.trainingDone}
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      </div>
                    )}
                    {activeVerticalTab === 3 && (
                      <div id="tab-4" className="tab-content">
                        <div class="list" id="style-1">
                          <ul>
                            {data.activity.decline
                              .sort((a, b) =>
                                a.firstName.localeCompare(b.firstName)
                              )
                              .map((customer, i) => {
                                return (
                                  <li key={i}>
                                    <div
                                      onClick={() =>
                                        props.onCustomerSelect(customer)
                                      }
                                      className="user-wrapper"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <li>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            marginLeft: "30px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              margin: "7px 0px",
                                              padding: "5px",
                                            }}
                                          >
                                            <Avatar
                                              src={customer.profilePicture}
                                              style={{
                                                backgroundColor: "#bdbdbd",
                                                borderRadius: "100%",
                                                width: "30px",
                                                height: "30px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                fontSize: "12px",
                                                color: "white",
                                              }}
                                            >
                                              {getInitials(
                                                `${customer.firstName} ${customer.lastName}`
                                              )}
                                            </Avatar>
                                            <div
                                              style={{
                                                marginLeft: "20px",
                                                fontSize: "13px",
                                                fontWeight: "500",
                                              }}
                                            >
                                              {customer.firstName}{" "}
                                              {customer.lastName}
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <div>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            background: "#F7F7F7",
                                            borderRadius: "3px",
                                            width: "25px",
                                            height: "25px",
                                          }}
                                        >
                                          -
                                          {
                                            customer.stats.lastWeek
                                              .trainingMissed
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    students: state.auth.students,
    notifications: state.messaging.notifications,
  };
};
export default connect(mapStateToProps)(Monitoring);
