/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  Link,
  Popover,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import InputIcon from "@material-ui/icons/Input";
import Logo from "../../components/Logo";
import Cookies from "js-cookie";
import { connect } from "react-redux";
import { logout, toggleConversationList } from "../../korper-redux/actions";
import ConversationListItem from "../../views/messages/MessagesView/components/ConversationListItem";
import moment from "moment";
import "moment/locale/fr.js";
moment.locale("fr");

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "black",
  },
  avatar: {
    width: 60,
    height: 60,
  },
}));

const TopBar = ({
  className,
  onMobileNavOpen,
  logout,
  user,
  toggleConversationList,
  ...rest
}) => {
  const classes = useStyles();
  const [notifications] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const [alerts, setAlerts] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    getAlerts();
  }, [rest.students, rest.notifications]);

  const getAlerts = () => {
    // let conversations = rest.students.filter(customer => customer.coachNotif && customer.lastMessageDate).map(customer => {
    //   let time = null

    //   let date = moment(customer.lastMessageDate)

    //   if (moment().isSame(date, 'day')) {
    //     time = date.format('HH:mm')
    //   }
    //   else {
    //     time = date.format('DD/MM')
    //   }

    //   return {
    //     type: 'conversation',
    //     name: `${customer.firstName} ${customer.lastName}`,
    //     text: customer.coachNotif > 1 ? `${customer.coachNotif} nouveaux messages` : `1 nouveau message`,
    //     customer,
    //     time,
    //     date
    //   };
    // })

    let notifications = rest.notifications.map((notification) => {
      let time = null;

      let date = moment(notification.createdAt);

      if (moment().isSame(date, "day")) {
        time = date.format("HH:mm");
      } else {
        time = date.format("DD/MM");
      }

      return {
        type: "notification",
        name: notification.title,
        text: `${notification.text.substring(0, 70)}${notification.text.length > 70 ? "..." : ""}`,
        customer: notification.customer,
        time,
        date,
      };
    });
    //let newAlerts = [...conversations, ...notifications]
    let newAlerts = [...notifications];

    newAlerts.sort((a, b) => b.date.diff(a.date));

    setAlerts(newAlerts);
  };

  const handleAlertClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAlertClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl) && alerts.length > 0;
  const id = open ? "simple-popover" : undefined;

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0}>
      <Toolbar style={{ height: "45px", minHeight: "45px" }}>
        <Hidden smDown>
          <IconButton
            style={{ marginRight: "10px" }}
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Hidden mdUp>
          <IconButton
            style={{ marginRight: "10px" }}
            color="inherit"
            onClick={() => toggleConversationList()}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <RouterLink to="/app/messages">
          <Logo />
        </RouterLink>
        <Hidden smDown>
          <RouterLink to="/app/messages">
            <span
              style={{
                color: "white",
                fontSize: "14px",
                marginLeft: 18,
                textDecoration:
                  location.pathname === "/app/messages"
                    ? "underline"
                    : undefined,
              }}
            >
              {`Messages`}
            </span>
          </RouterLink>
          <RouterLink to="/app/customers">
            <span
              style={{
                color: "white",
                fontSize: "14px",
                marginLeft: 18,
                textDecoration:
                  location.pathname === "/app/customers"
                    ? "underline"
                    : undefined,
              }}
            >
              {`Plan d’entrainement`}
            </span>
          </RouterLink>
        </Hidden>
        <Box flexGrow={1} />
        <IconButton
          color="inherit"
          aria-describedby={id}
          variant="contained"
          onClick={handleAlertClick}
        >
          <Badge badgeContent={alerts.length} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleAlertClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <div style={{ width: 280, maxHeight: 60 * 5, overflowY: "auto" }}>
            {alerts.map((alert, i) => (
              <RouterLink
                key={i}
                to={`/app/messages?customer=${alert.customer?._id}`}
              >
                <ConversationListItem onClick={handleAlertClose} data={alert} />
              </RouterLink>
            ))}
          </div>
        </Popover>
        <a
          style={{ color: "white" }}
          onClick={() => {
            Cookies.remove("user_token", { domain: "korper.io" });
            logout();
          }}
        >
          <IconButton color="inherit">
            <InputIcon />
          </IconButton>
        </a>
        {/* <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden> */}
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    students: state.auth.students,
    notifications: state.messaging.notifications,
  };
};

const mapDispatchToProps = { logout, toggleConversationList };

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
