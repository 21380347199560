import io from 'socket.io-client'
import store from './korper-redux/store'
import {
    setMessages,
    addStudent,
    updateStudent,
    setConnected,
    setIsTraining,
    setNotifications,
    setIsTrainingDone,
} from './korper-redux/actions'
import { batch } from 'react-redux'

const apiUrl = 'https://online.korper.io'

let socket

let coachId

export let idSocket;

socket = io(apiUrl)

socket?.on('connect', () => {
    if (coachId) {
        register(coachId)
    }
})

socket?.on('newMessage', (message, customer) => {
    const messaging = store.getState().messaging

    if (messaging.customerId && messaging.customerId === customer._id) {
        store.dispatch(setMessages([message, ...messaging.messages], customer._id))
        store.dispatch(updateStudent({ ...customer, coachNotif: 0 }))
    }
    else {
        store.dispatch(updateStudent(customer))

        // const audio = new Audio("https://proxy.notificationsounds.com/notification-sounds/light-562/download/file-sounds-1096-light.mp3")
        const audio = new Audio("/SF-harder.mp3")
        audio.play()
    }
})

socket?.on('updateMessage', (message, customer) => {
    const messaging = store.getState().messaging

    if (messaging.customerId && messaging.customerId === customer._id) {
        const index = messaging.messages.findIndex(v => v._id === message._id)

        if (index >= 0) {
            let newMessages = [...messaging.messages]
            newMessages[index] = message

            store.dispatch(setMessages(newMessages, customer._id))
        }
    }
})

socket?.on('newNotification', (notification) => {
    const messaging = store.getState().messaging

    store.dispatch(setNotifications([...messaging.notifications, notification]))
})

socket?.on('newStudent', (notification, customer) => {
    const messaging = store.getState().messaging

    store.dispatch(addStudent(customer))
    store.dispatch(setNotifications([...messaging.notifications, notification]))
})

socket?.on('customerDidConnect', (id) => {
    store.dispatch(setConnected(id, true))
})

socket?.on('customerDidDisconnect', (id) => {
    store.dispatch(setConnected(id, false))
})

socket?.on('customerTrainingDidStart', (id) => {
    store.dispatch(setIsTraining(id, true))
})

socket?.on('customerTrainingDidFinish', (id) => {

    batch(() => {
        store.dispatch(setIsTrainingDone(id, new Date()))
        store.dispatch(setIsTraining(id, false))
    })

})

socket?.on('disconnect', () => {
})

export const register = (id) => {
    coachId = id
    socket?.emit('coach_register', { id })
    idSocket = socket.id;
}
