/* eslint-disable */
import "react-perfect-scrollbar/dist/css/styles.css";
import React, { useEffect, useState, useCallback } from "react";
import { useRoutes } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import GlobalStyles from "./components/GlobalStyles";
import Toast from "./components/alertToastify/Toast";
import "./mixins/chartjs";
import theme from "./theme";
import routes from "./routes";
import Cookies from "js-cookie";
import axios from "axios";
import moment from "moment";
import { connect } from "react-redux";
import {
  login,
  logout,
  setNotifications,
  setExercices,
  setProgStatus,
  showHiddenStudents
} from "./korper-redux/actions";
import { register } from "./socket";
import Loading from "./components/LoadingScreen/Loading";

moment.updateLocale("fr", {
  relativeTime: {
    future: "Dans %s",
    past: "Il y a %s",
    s: "%d sec",
    m: "%d min",
    h: "%dh",
    d: "%d jour",
    dd: "%d jours",
  },
});

const App = (props) => {
  const retrieveToken = () => {
    console.log("Checking if is logged");

    let token = Cookies.get("user_token");

    if (!token) {
      props.logout();
    } else {
      onLogin(token);
    }
  };

  const onLogin = async (token) => {
    try {
      let response = await axios.post("https://online.korper.io/coachs/login", {
        token,
      });

      if (response.data.success) {
        Cookies.set("user_token", response.data.token, {
          expires: 14,
          domain: "korper.io",
        });
        register(response.data.coach._id);
        props.login(response.data.coach, response.data.students, response.data.hidden);

        try {
          let res = await axios.get(
            "https://online.korper.io/coachs/notifications",
            { withCredentials: true }
          );

          if (res.data.success) {
            props.setNotifications(res.data.notifications);
          }
        } catch (err) {
          console.error(err);
        }

        try {
          let res = await axios.get(
            "https://online.korper.io/coachs/exercices",
            { withCredentials: true }
          );

          if (res.data.success) {
            props.setExercices(res.data.exercices);
          }
        } catch (err) {
          console.error(err);
        }

        try {
          let res = await axios.get(
            "https://online.korper.io/coachs/prog/status",
            { withCredentials: true }
          );

          if (res.data.success) {
            props.setProgStatus(res.data.progStatus);
          }
        } catch (err) {
          console.error(err);
        }
      } else {
        props.logout();
      }
    } catch (err) {
      console.error(err);
      props.logout();
    }
  };

  useEffect(() => {
    retrieveToken();
    // window.addEventListener('focus', retrieveToken)

    const keyup = (event) => {
      if (event.ctrlKey && event.shiftKey && event.altKey && event.code === "KeyP") {
        console.log('Hidden shortcut')
        props.showHiddenStudents();
      }
    }

    window.addEventListener('keyup', keyup)

    return () => {
      // window.removeEventListener('focus', retrieveToken)
      window.removeEventListener('keyup', keyup)
    }
  }, []);

  const routing = useRoutes(routes(!!props.user));

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {props.user === null ? <Loading /> : routing}
      <Toast />
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = { login, logout, setNotifications, setExercices, setProgStatus, showHiddenStudents };

export default connect(mapStateToProps, mapDispatchToProps)(App);
