import React from 'react';

const Logo = (props) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <img
        alt="Logo"
        src="https://korper.io/assets/logo-korper-blanc.png"
        width="100px"
        {...props}
      />
      {/* <div style={{
        background: 'linear-gradient(90deg, #c33764, #1d2671)',
        height: '3px',
        width: '100px',
        marginTop: '6px'
      }}
      /> */}
    </div>
  );
};

export default Logo;
