import React, { useState, useMemo, useRef, useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import SendIcon from '@material-ui/icons/Send';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import './Compose.css';
import moment from 'moment';
import { connect } from 'react-redux';

const mapStateToProps = state => {
  return {
    students: state.auth.students
  };
};

export default connect(mapStateToProps)(function Compose(props) {
  const [text, setText] = useState('');
  const inputRef = useRef(null);

  const onSend = () => {
    if (text) {
      props.onMessage(text);
      setText('');
    }
  };

  const connectionText = useMemo(() => {
    let student = props.students?.find(
      s => s._id === props.selectedCustomer._id
    );

    if (student?.connected) {
      return (
        <div>
          <div
            style={{
              display: 'inline-block',
              background: 'green',
              width: 8,
              height: 8,
              borderRadius: 5,
              marginRight: 6
            }}
          />{' '}
          En ligne
        </div>
      );
    } else if (student?.lastConnection) {
      return `Connecté il y a ${moment(student?.lastConnection).fromNow(
        true
      )} (${moment(student?.lastConnection).format('LT')})`;
    }
    return 'Jamais connecté';
  }, [props.students, props.selectedCustomer]);

  const handleInputChange = e => {
    setText(e.target.value);
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      const { height } = entries[0].contentRect;
      props.onInputHeightChange(height);
    });

    resizeObserver.observe(inputRef.current);

    return () => {
      resizeObserver.unobserve(inputRef.current);
    };
  });

  document.addEventListener('keydown', function (event) {
    if ((event.ctrlKey || event.metaKey) && event.key === 'Enter') {
      props.unSelectCustomer();
    }
  });
  return (
    <div className="wrapper-compose">
      <div
        style={{
          fontWeight: '500',
          padding: '4px 8px',
          backgroundColor: '#e9f4ff',
          display: 'flex',
          justifyContent: 'center',
          fontSize: '12px'
        }}
      >
        {connectionText}
      </div>
      <div className="compose">
        {!props.disabled && (
          <label htmlFor="icon-button-file">
            <input
              style={{ display: 'none' }}
              id="icon-button-file"
              type="file"
              accept="image/png, image/jpeg, image/gif, video/*"
              onChange={props.onImage}
            />
            <IconButton
              style={{ marginRight: '5px', height: '39px' }}
              aria-label="upload picture"
              component="span"
            >
              <AttachFileIcon style={{ transform: 'rotate(45deg)' }} />
            </IconButton>
          </label>
        )}

        <div style={{ display: 'flex', alignItems: 'flex-end', width: '100%' }}>
          <TextareaAutosize
            maxRows={8}
            className="compose-input"
            aria-label="maximum height"
            placeholder={
              props.disabled
                ? "Ce client n'a pas d'abonnement Korper actif"
                : 'Écrit ton message'
            }
            value={text}
            ref={inputRef}
            type="text"
            onChange={e => handleInputChange(e)}
            onHeightChange={height => handleInputHeightChange(height)}
            disabled={props.disabled}
            autoFocus
          />
        </div>

        {!props.disabled && (
          <IconButton
            style={{ marginLeft: '5px', height: '39px' }}
            onClick={onSend}
          >
            <SendIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
});
