import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
// import moment from 'moment';
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  ListItemAvatar,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  InputAdornment,
  SvgIcon,
  TextField,
} from "@material-ui/core";
import getInitials from "../../../utils/getInitials";
import { connect } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "0px",
    height: "calc(100dvh - 150px)",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  open: {
    maxWidth: "230px",
  },
  avatar: {
    marginRight: theme.spacing(1),
    width: "38px",
    height: "38px",
  },
  names: {
    fontSize: 14,
  },
  listItemText: {
    margin: 0,
  },
}));

const Results = ({
  className,
  customers,
  user,
  onCustomerSelect,
  selectedCustomer,
  students,
  open,
  progStatus,
  ...rest
}) => {
  const classes = useStyles();

  const [searchCustomer, setSearchCustomer] = useState("");

  const filteredSearchCustomer = students?.length
    ? students.filter((v) => {
        let result = true;
        if (searchCustomer) {
          let txt = new RegExp(searchCustomer, "i");
          result = `${v.firstName} ${v.lastName}`.match(txt);
        }

        return result;
      })
    : [];

  const getProgStatus = (id) => {
    if (progStatus[id]) {
      if (progStatus[id].count > 0) {
        if (progStatus[id].available === progStatus[id].count) {
          return (
            <div
              style={{
                backgroundColor: "#4CAF50",
                width: "16px",
                height: "16px",
                borderRadius: "50%",
                marginRight: "4px",
                marginLeft: "4px",
                border: "1px solid #4b8a27",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span style={{ color: "white", fontSize: 10 }}>
                {progStatus[id].count}
              </span>
            </div>
          );
        } else {
          return (
            <div
              style={{
                backgroundColor: "#FFC107",
                width: "16px",
                height: "16px",
                borderRadius: "50%",
                marginRight: "4px",
                marginLeft: "4px",
                border: "1px solid #be9414",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span style={{ color: "white", fontSize: 10 }}>
                {progStatus[id].count}
              </span>
            </div>
          );
        }
      }
    }

    return (
      <div
        style={{
          width: "16px",
          height: "16px",
          borderRadius: "50%",
          marginRight: "4px",
          marginLeft: "4px",
          border: "1px solid transparent",
        }}
      />
    );
  };

  return (
    <Card
      className={
        open
          ? clsx(classes.root, className, classes.open)
          : clsx(classes.root, className)
      }
      {...rest}
    >
      <PerfectScrollbar>
        <Box style={{ paddingRight: 10, paddingLeft: 10, paddingTop: 6 }}>
          <TextField
            style={{
              marginTop: "1px",
              backgroundColor: "white",
            }}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" style={{ color: "#3888FF" }}>
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            placeholder="Rechercher"
            variant="outlined"
            value={searchCustomer}
            onChange={(e) => [setSearchCustomer(e.target.value)]}
            autoFocus
          />
          <List>
            {filteredSearchCustomer?.length ? (
              [...filteredSearchCustomer]
                .sort((a, b) => a.firstName.localeCompare(b.firstName))
                .map((customer) => (
                  <ListItem
                    style={{
                      backgroundColor:
                        selectedCustomer?._id === customer?._id
                          ? "#e4e4e4"
                          : undefined,
                      paddingLeft: 0,
                      paddingRight: "10px",
                    }}
                    button
                    key={customer._id}
                    onClick={() => onCustomerSelect(customer)}
                  >
                    {getProgStatus(customer._id)}
                    <ListItemAvatar>
                      <Avatar
                        className={classes.avatar}
                        src={customer.profilePicture}
                      >
                        {getInitials(
                          `${customer.firstName} ${customer.lastName}`
                        )}
                      </Avatar>
                    </ListItemAvatar>
                    <div style={{ flexDirection: "column" }}>
                      <ListItemText
                        classes={{
                          primary: classes.names,
                          root: classes.listItemText,
                        }}
                        primary={`${customer.firstName} ${customer.lastName}`}
                      />
                      {(!customer.subscriptionStatus ||
                        customer.subscriptionStatus === "none") && (
                        <span style={{ color: "#eb1e1e", fontSize: 12 }}>
                          Abonnement résilié
                        </span>
                      )}
                    </div>
                  </ListItem>
                ))
            ) : (
              <ListItem button>
                <ListItemAvatar>
                  <Avatar className={classes.avatar}>{getInitials(`A`)}</Avatar>
                </ListItemAvatar>
                <ListItemText
                  classes={{
                    primary: classes.names,
                    root: classes.listItemText,
                  }}
                  primary={`Aucun clients`}
                />
              </ListItem>
            )}
          </List>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  onCustomerSelect: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    students: state.auth.students,
    progStatus: state.auth.progStatus,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Results);
