import React from "react";
import { Avatar } from "@material-ui/core";
import getInitials from "../../../../../utils/getInitials";
import moment from "moment";
import "./DrawerItem.css";

export default function DrawerItem(props) {
  let bothName = `${props.customer.firstName} ${props.customer.lastName}`;
  let sliceLength = 15;
  let customerSliceName = `${bothName?.substring(0, sliceLength)}${
    bothName.length > sliceLength ? "..." : ""
  }`;

  const lastTrainingDoneTime = () => {
    if (moment(props.customer.isTrainingDone).fromNow() === "Invalid date")
      return "Il y a 1 sec";
    return moment(props.customer.isTrainingDone).fromNow();
  };

  return (
    <div
      onClick={() => props.onCustomerSelect(props.customer)}
      id="drawer"
      style={{
        display: "flex",
        alignItems: "center",
        padding: "10px 10px 10px 35px",
        cursor: "pointer",
        backgroundColor: props.selected ? "#2B96F2" : undefined,
        borderBottom: "solid 1px #DDD",
      }}
    >
      <div style={{ position: "relative" }}>
        <Avatar src={null}>
          {getInitials(
            `${props.customer.firstName} ${props.customer.lastName}`
          )}
        </Avatar>
      </div>
      <div className="conversation-info">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <h4
              className="conversation-title"
              style={{
                fontSize: "12px",
                color: props.selected ? "white" : undefined,
              }}
            >
              {props.showTraningDate
                ? customerSliceName
                : props.customer.firstName + " " + props.customer.lastName}
            </h4>
          </div>
          {props.showTraningDate && (
            <span className="conversation-warning">
              {lastTrainingDoneTime()}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
