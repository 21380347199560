import React, { useEffect } from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Avatar, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import getInitials from "../../../../../utils/getInitials";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import MailIcon from "@material-ui/icons/Mail";
import DraftsIcon from "@material-ui/icons/Drafts";
import { connect } from "react-redux";
import { addNotif } from "../../../../../korper-redux/actions";
import axios from "axios";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import moment from "moment";

import "./ConversationListItem.css";

const useStyles = makeStyles((theme) => ({
  avatar: {
    marginRight: theme.spacing(1),
  },
  avatarConnected: {
    marginRight: theme.spacing(1),
    border: "solid 2px #3dd33d",
  },
  deleteBtn: {
    marginRight: theme.spacing(1),
    backgroundColor: "#ff6060",
  },
}));

function ConversationListItem(props) {
  const { name, text, dot, onDelete, time, timeWarning, customer } = props.data;

  const onAddNotif = () => {
    props.addNotif(customer._id);
    axios.post(
      `https://online.korper.io/coachs/customer/${customer._id}/notif`,
      {},
      {
        withCredentials: true,
      }
    );
  };

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const handlePopover = (e) => {
    e.stopPropagation();
    setAnchorEl(null);

    if (props.selected) {
      props.unSelectCustomer();
      //!\\ BUG HERE
      setTimeout(() => {
        onAddNotif();
      }, 2000);
    } else {
      onAddNotif();
    }
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const newBadge = (createdAt) => {
    const ageAccount = moment().diff(moment(createdAt), "days", true);
    const dateZone = moment(createdAt).isBetween(
      moment("2023-04-21"),
      moment(),
      null,
      "[]"
    );
    //console.log(ageAccount,dateZone,ageAccount < 14 && dateZone,customer.firstName)
    return ageAccount < 14 && dateZone;
  };

  return (
    <div
      data-id={customer._id}
      data-type="conversation-item"
      className={
        props.selected
          ? "conversation-list-item-selected student-item"
          : "conversation-list-item student-item"
      }
      onClick={props.onClick}
    >
      {onDelete && props.selected ? (
        <Avatar className={classes.deleteBtn} src={null} onClick={onDelete}>
          <CloseIcon style={{ color: "#fff" }} />
        </Avatar>
      ) : (
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "inline-block",
              background: dot ? "#2B96F2" : "transparent",
              width: 8,
              height: 8,
              borderRadius: 5,
              marginRight: 6,
            }}
          />

          <Avatar
            className={
              customer.connected ? classes.avatarConnected : classes.avatar
            }
            src={customer.profilePicture}
          >
            {getInitials(`${customer.firstName} ${customer.lastName}`)}
          </Avatar>
          {customer.connected && customer.isTraining && (
            <div
              style={{
                width: 18,
                height: 18,
                borderRadius: "50%",
                background: "#121212",
                position: "absolute",
                bottom: -4,
                right: 6,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FitnessCenterIcon style={{ color: "white", fontSize: 13 }} />
            </div>
          )}
        </div>
      )}
      <div className="conversation-info">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <h4 className="conversation-title">{name}</h4>
            {newBadge(customer.createdAt) ? (
              <div
                style={{
                  background: "black",
                  color: "white",
                  padding: "1px 6px",
                  fontSize: "10px",
                  borderRadius: "15px",
                  marginLeft: "10px",
                }}
              >
                New
              </div>
            ) : null}
          </div>
        </div>
        <p className="conversation-snippet">
          {(!customer.subscriptionStatus || customer.subscriptionStatus === "none") ? (
            <span style={{ color: props.selected ? "#ebebeb" : "#eb1e1e" }}>
              Abonnement résilié
            </span>
          ) : (
            text
          )}
        </p>
        {timeWarning && (
          <span className="conversation-snippet warningTime">
            {timeWarning}
          </span>
        )}
        {time && <span className="conversation-snippet">{time}</span>}
      </div>

      <div id="popup">
        <Button aria-describedby={id} onClick={handleClick}>
          <img
            src="../static/images/3-vertical-dots-icon.png"
            style={{
              color: "#989898",
              cursor: "copy",
              height: "63px",
            }}
          />
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Button
            onClick={handlePopover}
            style={{
              padding: "8px 10px 8px 10px",
              textTransform: "none",
            }}
          >
            <Typography style={{ display: "flex", alignItems: "center" }}>
              <MailIcon style={{ marginRight: "10px" }} /> Marquer non lu
            </Typography>
          </Button>
        </Popover>
      </div>
    </div>
  );
}

const mapDispatchToProps = { addNotif };

export default connect(null, mapDispatchToProps)(ConversationListItem);
