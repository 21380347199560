import React from "react";
import { Box, Container, makeStyles } from "@material-ui/core";
import Page from "../../../components/Page";

import Messenger from "./components/Messenger";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
  },
}));

const MessagesView = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Messages">
      <Messenger />
    </Page>
  );
};

export default MessagesView;
