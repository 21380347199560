import React, { useState, forwardRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Button,
  IconButton,
  ButtonGroup,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Search as SearchIcon } from "react-feather";
import PlayCircleFilledRoundedIcon from "@material-ui/icons/PlayCircleFilledRounded";
import moment from "moment";
import "./style.css";
import "moment/locale/fr.js";
moment.locale("fr");

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  dayButton: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
}));

const Toolbar = forwardRef(
  (
    {
      className,
      selectedYear,
      setSelectedYear,
      selectedWeek,
      setSelectedWeek,
      formsRef,
      setSelectedToolbarDay,
      selectedToolbarDay,
      ...rest
    },
    ref
  ) => {
    const [selectedDay, setSelectedDay] = useState(
      parseInt(moment().isoWeekday())
    );

    // useImperativeHandle(ref, () => ({
    //   onFormsScroll() {
    //     if (formsRef?.current) {
    //       let newDay = Math.floor(Math.max(formsRef.current.scrollLeft + 270, 0) / 410) + 1
    //       if (newDay !== selectedDay) {
    //         setSelectedDay(newDay)
    //       }
    //     }
    //   }
    // }))

    const classes = useStyles();

    const selectedDate = moment()
      .isoWeekYear(selectedYear)
      .isoWeek(selectedWeek)
      .isoWeekday(selectedToolbarDay);

    const startOfWeek = moment(selectedDate).startOf("isoWeek");

    const endOfWeek = moment(selectedDate).endOf("isoWeek"); 

    const onDayChange = (d) => {
      if (formsRef?.current) {
        formsRef.current.scrollLeft = (d - 1) * 410;
      }
      if (d !== selectedToolbarDay) {
        setSelectedToolbarDay(d);
      }
    };

    return (
      <div className={clsx(classes.root, className)} {...rest}>
        {/* <Box
        display="flex"
        justifyContent="flex-end"
      >
        <Button className={classes.importButton}>
          Import
        </Button>
        <Button className={classes.exportButton}>
          Export
        </Button>
        <Button
          color="primary"
          variant="contained"
        >
          Add customer
        </Button>
      </Box> */}
        <Card style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
          <CardContent
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0px",
            }}
          >
            <IconButton
              size="small"
              onClick={() => {
                let newDate = moment(selectedDate).subtract(1, "isoWeek");
                onDayChange(1);
                setSelectedWeek(parseInt(newDate.isoWeek()));
                setSelectedYear(parseInt(newDate.isoWeekYear()));
              }}
            >
              <PlayCircleFilledRoundedIcon
                style={{ transform: "scale(-1, 1)" }}
                fontSize="inherit"
              />
            </IconButton>
            <Typography
              color="textSecondary"
              variant="h5"
              style={{ margin: "6px", fontSize: 15 }}
            >
              Semaine du {startOfWeek.format("DD")} au{" "}
              {endOfWeek.format("DD MMMM YYYY")}
            </Typography>
            <IconButton
              size="small"
              onClick={() => {
                let newDate = moment(selectedDate).add(1, "isoWeek");
                onDayChange(1);
                setSelectedWeek(parseInt(newDate.isoWeek()));
                setSelectedYear(parseInt(newDate.isoWeekYear()));
              }}
            >
              <PlayCircleFilledRoundedIcon fontSize="inherit" />
            </IconButton>
          </CardContent>
        </Card>
        <ButtonGroup
          id="toolbargroup"
          style={{ borderTopLeftRadius: 0 }}
          size="small"
        >
          <Button
            classes={{ root: classes.dayButton }}
            onClick={() => onDayChange(1)}
            style={
              selectedToolbarDay !== 1
                ? {}
                : { backgroundColor: "#797979", color: "white" }
            }
          >
            Lundi
          </Button>
          <Button
            classes={{ root: classes.dayButton }}
            onClick={() => onDayChange(2)}
            style={
              selectedToolbarDay !== 2
                ? {}
                : { backgroundColor: "#797979", color: "white" }
            }
          >
            Mardi
          </Button>
          <Button
            classes={{ root: classes.dayButton }}
            onClick={() => onDayChange(3)}
            style={
              selectedToolbarDay !== 3
                ? {}
                : { backgroundColor: "#797979", color: "white" }
            }
          >
            Mercredi
          </Button>
          <Button
            classes={{ root: classes.dayButton }}
            onClick={() => onDayChange(4)}
            style={
              selectedToolbarDay !== 4
                ? {}
                : { backgroundColor: "#797979", color: "white" }
            }
          >
            Jeudi
          </Button>
          <Button
            classes={{ root: classes.dayButton }}
            onClick={() => onDayChange(5)}
            style={
              selectedToolbarDay !== 5
                ? {}
                : { backgroundColor: "#797979", color: "white" }
            }
          >
            Vendredi
          </Button>
          <Button
            classes={{ root: classes.dayButton }}
            onClick={() => onDayChange(6)}
            style={
              selectedToolbarDay !== 6
                ? {}
                : { backgroundColor: "#797979", color: "white" }
            }
          >
            Samedi
          </Button>
          <Button
            classes={{ root: classes.dayButton }}
            onClick={() => onDayChange(7)}
            style={
              selectedToolbarDay !== 7
                ? {}
                : { backgroundColor: "#797979", color: "white" }
            }
          >
            Dimanche
          </Button>
        </ButtonGroup>
      </div>
    );
  }
);

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
