import React, { useEffect, useState } from 'react';
import ConversationList from '../ConversationList';
import MessageList from '../MessageList';
import './Messenger.css';
import CustomerInfos from '../../../../customer/CustomerListView/CustomerInfos';
import { connect } from 'react-redux';
import { toggleConversationList } from '../../../../../korper-redux/actions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Monitoring from '../Monitoring';

const mapStateToProps = state => {
  return {
    conversationListOpen: state.messaging.conversationListOpen,
    coach: state.auth.user,
    students: state.auth.students,
  };
};

const mapDispatchToProps = { toggleConversationList };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(function Messenger(props) {
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [loading, setLoading] = useState(false);

  const onCustomerSelect = customer => {
    if (!loading) {
      setSelectedCustomer(customer);
      props.toggleConversationList();
      window.history.replaceState(null, '', `?customer=${customer._id}`);
    }
  };
  const unSelectCustomer = () => {
    setSelectedCustomer(null);
    window.history.replaceState(null, '', window.location.pathname);
  };

  return (
    <div className="messenger">
      {/* <Toolbar
          title="Messenger"
          leftItems={[
            <ToolbarButton key="cog" icon="ion-ios-cog" />
          ]}
          rightItems={[
            <ToolbarButton key="add" icon="ion-ios-add-circle-outline" />
          ]}
        /> */}

      {/* <Toolbar
          title="Conversation Title"
          rightItems={[
            <ToolbarButton key="info" icon="ion-ios-information-circle-outline" />,
            <ToolbarButton key="video" icon="ion-ios-videocam" />,
            <ToolbarButton key="phone" icon="ion-ios-call" />
          ]}
        /> */}

      <div id="scrollableListconvestation"
        className={`scrollable sidebar${
          props.conversationListOpen ? ' open' : ''
        }`}
      >
        <ConversationList
          onCustomerSelect={onCustomerSelect}
          unSelectCustomer={unSelectCustomer}
          selectedCustomer={selectedCustomer}
          setSelectedCustomer={setSelectedCustomer}
        />
      </div>

      <div
        className="content"
        style={{
          flexGrow: 1,
          overflow: selectedCustomer ? '' : 'scroll',
          background: selectedCustomer ? 'white' : '#F4F4F4',
          
        }}
      >
        {selectedCustomer ? (
          <>
            <div style={{ left: '10px', top: '10px', position: 'relative' }}>
              <IconButton
                onClick={() => unSelectCustomer()}
                style={{
                  borderRadius: '100%',
                  border: 'solid #DDD 1px',
                  position: 'absolute',
                  padding: '3px',
                  backgroundColor: 'black'
                }}
                variant="outlined"
              >
                <ArrowBackIcon style={{ color: 'white' }} fontSize="inherit" />
              </IconButton>
            </div>
            <MessageList
              setLoading={setLoading}
              loading={loading}
              unSelectCustomer={unSelectCustomer}
              selectedCustomer={selectedCustomer}
            />
          </>
        ) : (
          <Monitoring
            onCustomerSelect={onCustomerSelect}
            selectedCustomer={selectedCustomer}
            coachFirstName={props.coach.firstName}
          />
        )}
      </div>

      {selectedCustomer && (
        <div className="scrollable footer">
          <CustomerInfos
            open={true}
            showTrainingLink
            selectedCustomer={selectedCustomer}
          />
        </div>
      )}
    </div>
  );
});
