import React, { useEffect, useState, useRef, useCallback } from 'react';
import Compose from '../Compose';
import Toolbar from '../Toolbar';
import ToolbarButton from '../ToolbarButton';
import VideocamIcon from '@material-ui/icons/Videocam';
import Refresh from '@material-ui/icons/Refresh';
import Message from '../Message';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  setMessages,
  clearNotif,
  updateStudent
} from '../../../../../korper-redux/actions';
import axios from 'axios';
import Resizer from 'react-image-file-resizer';
import { idSocket } from '../../../../../socket';

import './MessageList.css';
import { get } from 'lodash';

const MY_USER_ID = 'coach';

function MessageList(props) {
  const onClearNotif = () => {
    axios.delete(
      `https://online.korper.io/coachs/customer/${props.selectedCustomer._id}/notif`,
      { withCredentials: true }
    );
    props.clearNotif(props.selectedCustomer._id);
  };

  const currentPage = useRef(1);
  const msgLoading = useRef(false);

  const getMoreMessages = useCallback(async () => {
    try {
      if (msgLoading.current) return;
      msgLoading.current = true;
      props.setLoading(true);

      let res = await axios.get(
        `https://online.korper.io/coachs/customer/${props.selectedCustomer._id}/messaging?page=${currentPage.current}&limit=40`,
        { withCredentials: true }
      );
      currentPage.current += 1;
      if (res.data.success) {
        props.setMessages(
          [...(props.messages || []), ...res.data.messages],
          props.selectedCustomer._id
        );

        onClearNotif();
        props.setLoading(false);
      } else {
        props.setLoading(false);
      }
    } catch (error) {
      console.error(error);
      props.setLoading(false);
    } finally {
      msgLoading.current = false;
    }
  }, [props.messages, props.selectedCustomer]);

  const getMessages = useCallback(async () => {
    try {
      if (msgLoading.current) return;
      msgLoading.current = true;
      currentPage.current = 1;
      props.setLoading(true);

      let res = await axios.get(
        `https://online.korper.io/coachs/customer/${props.selectedCustomer._id}/messaging?page=1&limit=40`,
        { withCredentials: true }
      );
      currentPage.current += 1;
      if (res.data.success) {
        props.setMessages(res.data.messages, props.selectedCustomer._id);

        onClearNotif();
        props.setLoading(false);
      } else {
        props.setLoading(false);
      }
    } catch (error) {
      console.error(error);
      props.setLoading(false);
    } finally {
      msgLoading.current = false;
    }
  }, [props.messages, props.selectedCustomer]);

  // useEffect(() => {
  //   window.addEventListener('focus', getMessages)

  //   return () => {
  //     window.removeEventListener('focus', getMessages)
  //   }
  // }, [])

  useEffect(() => {
    getMessages();

    return () => {
      onClearNotif();

      props.setMessages(null, null);
    };
  }, [props.selectedCustomer]);

  // useEffect(() => {
  //   let element = document.getElementById("message-list")

  //   if (element) {
  //     element.scrollTop = element.scrollHeight
  //   }
  // }, [props.messages])
  const messageList = useRef(null);
  const onMessage = async (
    text,
    image = undefined,
    video = undefined,
    data = undefined,
    coachKey = idSocket
  ) => {
    try {
      axios.post(
        `https://online.korper.io/coachs/customer/${props.selectedCustomer._id}/message`,
        {
          text,
          image,
          video,
          data,
          coachKey
        },
        { withCredentials: true }
      );
    } catch (error) {
      console.error(error);
    }

    let newMessage = {
      type: 'text',
      author: 'coach',
      text,
      image,
      video,
      data,
      createdAt: moment()
    };

    props.setMessages(
      [newMessage, ...props.messages],
      props.selectedCustomer._id
    );
    props.updateStudent({
      ...props.selectedCustomer,
      lastMessageText: text,
      lastMessageDate: newMessage.createdAt,
      coachNotif: 0
    });
  };

  // useEffect(() => {
  //   messageList.current?.scrollTo({ behavior: 'smooth', top: 0, left: 0 });
  // }, [props.messages?.length]);

  const resizeFile = file =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        1920,
        1920,
        'JPEG',
        70,
        0,
        uri => {
          resolve(uri);
        },
        'blob'
      );
    });

  function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  const onImage = async e => {
    try {
      let file = e.target.files[0];

      const fileType = file.type?.split('/')[0];

      let data = null;

      if (fileType === 'image') {
        if (file.type === 'image/gif') {
          data = file;
        } else {
          data = await resizeFile(file);
        }
      } else if (fileType === 'video') {
        data = file;
      }

      if (!data) {
        return;
      }

      try {
        const uid = Math.random()
          .toString(36)
          .substr(2, 9);

        let path = `/messaging/images/${props.user._id
          }-${uid}-${encodeURIComponent(file.name)}`;

        let res = await axios.put(
          `https://storage.bunnycdn.com/korperstorage${path}`,
          data,
          {
            headers: {
              AccessKey: '298fac42-a244-478f-935dde4f6252-7c69-4464',
              'Content-Type': 'application/octet-stream'
            }
          }
        );

        onMessage(
          null,
          fileType === 'image' ? `https://korperonline.b-cdn.net${path}` : null,
          fileType === 'video' ? `https://korperonline.b-cdn.net${path}` : null,
          { width: 1920, height: 1920 }
        );
      } catch (error) {
        console.error(error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formatSeconds = seconds => {
    return `${`0${Math.floor(seconds / 60)}`.slice(-2)}:${`0${seconds %
      60}`.slice(-2)}`;
  };

  const renderMessages = () => {
    if (!props.messages) {
      return null;
    }

    let i = 0;
    let messageCount = props.messages.length;
    let tempMessages = [];

    while (i < messageCount) {
      let current = props.messages[i];
      if (current.type === 'training') {
        let dateText = null;
        let date = moment(current.data.training.date.time);
        if (date.diff(moment(), 'days') === 0) {
          dateText = 'JOUR';
        } else {
          dateText = date.format('DD/MM/YY');
        }
        tempMessages.push(
          <div
            key={current?._id || i}
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <div
              className="training-container"
              style={{
                background: `url('https://online.korper.io/files${current.data.training.image}') no-repeat center`
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  bottom: 0,
                  right: 0,
                  background: '#00000050'
                }}
              />
              <span
                style={{
                  fontSize: '11px',
                  fontWeight: '700',
                  marginBottom: 20
                }}
              >
                SÉANCE DU {dateText}
              </span>
              <span style={{ fontSize: '16px', fontWeight: '700' }}>
                {current.data.training.title}
              </span>
              <span
                style={{
                  fontSize: '14px',
                  fontWeight: '600',
                  marginBottom: 20
                }}
              >
                {current.data.training.subtitle}
              </span>
              <span style={{ fontSize: '17px', fontWeight: '700' }}>
                {Math.ceil(current.data.training.totalTime / 60)} MIN
              </span>
            </div>
          </div>
        );
      } else if (current.type === 'trainingMissed') {
        let dateText = null;
        let date = moment(current.data.training.date.time);
        if (moment().diff(date, 'days') === 1) {
          dateText = "D'HIER";
        } else {
          dateText = `DU ${date.format('DD/MM/YY')}`;
        }
        tempMessages.push(
          <div
            key={current?._id || i}
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <div className="training-missed-container">
              <span
                style={{
                  fontSize: '11px',
                  fontWeight: '700',
                  marginBottom: 10,
                  color: '#dc3545'
                }}
              >
                ENTRAÎNEMENT {dateText} MANQUÉ
              </span>
              <span
                style={{
                  fontSize: '14px',
                  fontWeight: '400',
                  marginBottom: 16,
                  color: '#6c757d'
                }}
              >
                Hier
              </span>
              <span style={{ fontSize: '16px', fontWeight: '700' }}>
                {current.data.training.title}
              </span>
              <span style={{ fontSize: '14px', fontWeight: '600' }}>
                {current.data.training.subtitle}
              </span>
            </div>
          </div>
        );
      } else if (current.type === 'trainingSummary') {
        let missedExercices = [];
        let exercicePerformances = [];

        let index = 0;

        for (const circuit of current.data.training.circuits) {
          for (const exercice of circuit.exercices) {
            if (
              current.data.training.doneExercices &&
              !current.data.training.doneExercices.includes(index)
            ) {
              missedExercices.push(exercice.name);
            }

            if (current.data.training.performances) {
              if (current.data.training.performances[index]) {
                exercicePerformances.push({
                  name: exercice.name,
                  reps: current.data.training.performances[index].reps ?
                    {
                      new: current.data.training.performances[index].reps,
                      old: exercice.reps
                    } : undefined,
                  poids: current.data.training.performances[index].poids ?
                    {
                      new: current.data.training.performances[index].poids,
                      old: exercice.poids
                    } : undefined,
                  distance: current.data.training.performances[index].distance ?
                    {
                      new: current.data.training.performances[index].distance,
                      old: exercice.distance
                    } : undefined,
                })
              }
            }
            index++;
          }
        }

        //Merge duplicate names into one like : Pompes (x2)
        missedExercices = missedExercices.reduce((acc, curr) => {
          if (acc[curr]) {
            acc[curr]++;
          } else {
            acc[curr] = 1;
          }
          return acc;
        }, []);

        missedExercices = Object.keys(missedExercices).map(key => {
          if (missedExercices[key] > 1) {
            return `${key} (x${missedExercices[key]})`;
          } else {
            return key;
          }
        });

        tempMessages.push(
          <div
            key={current?._id || i}
            style={{
              width: '100%',
              alignSelf: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <div className="training-summary-container">
              <div
                style={{
                  backgroundColor: '#265F2C',
                  width: '100%',
                  padding: 10,
                  textAlign: 'center',
                  borderBottom: 'solid 1px #707070'
                }}
              >
                <span
                  style={{
                    fontSize: '15px',
                    fontWeight: '400',
                    marginBottom: 20,
                    color: 'white'
                  }}
                >
                  Entraînement complété
                </span>
              </div>
              <span
                style={{
                  fontSize: '17px',
                  fontWeight: '800',
                  marginTop: 10,
                  textAlign: 'center'
                }}
              >
                {current.data.training.title}
              </span>
              <span
                style={{
                  fontSize: '12px',
                  fontWeight: '600',
                  marginTop: 8,
                  textAlign: 'center'
                }}
              >
                {current.data.training.subtitle}
              </span>
              <span
                style={{
                  fontSize: '11px',
                  fontWeight: '400',
                  marginTop: 6,
                  textAlign: 'center'
                }}
              >
                {current.data.training.doneDate
                  ? moment(current.data.training.doneDate).format(
                    'DD/MM/YYYY - HH:mm'
                  )
                  : '...'}
              </span>
              <div style={{ width: "100%", display: 'flex', flexDirection: "row" }}>
                {missedExercices.length ? (
                  <div
                    style={{
                      width: exercicePerformances.length ? '50%' : '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                      borderTop: 'solid 1px #707070',
                      marginTop: 6,
                      paddingTop: 10,
                      paddingBottom: 10
                    }}
                  >
                    <span
                      style={{
                        fontSize: '15px',
                        fontWeight: '600',
                        color: '#9F9F9F',
                        marginBottom: 5
                      }}
                    >
                      IGNORÉ
                    </span>
                    {missedExercices.map((v, i) => (
                      <span
                        key={i}
                        style={{
                          fontSize: '13px',
                          fontWeight: '500',
                          marginBottom: 5
                        }}
                      >
                        {v}
                      </span>
                    ))}
                  </div>
                ) : null}
                {exercicePerformances.length ? (
                  <div
                    style={{
                      width: missedExercices.length ? '50%' : '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                      borderTop: 'solid 1px #707070',
                      marginTop: 6,
                      paddingTop: 10,
                      paddingBottom: 10,
                      borderLeftWidth: missedExercices.length ? 1 : 0,
                      borderLeftColor: '#707070',
                      borderLeftStyle: 'solid',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '15px',
                        fontWeight: '600',
                        color: '#9F9F9F',
                        marginBottom: 5
                      }}
                    >
                      MODIFIÉ
                    </span>
                    {exercicePerformances.map((v, i) =>
                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
                        <span style={{
                          fontSize: '13px',
                          fontWeight: '500',
                          width: '120px'
                        }}>{v.name}</span>
                        <div style={{ marginLeft: 10, display: 'flex', flexDirection: 'column' }}>
                          {v.reps && (
                            <span
                              style={{
                                fontSize: '13px',
                                fontWeight: '600',
                                marginBottom: 3
                              }}
                            >
                              <span style={{ color: "grey" }}>{v.reps.old}</span> {'->'} {v.reps.new} reps
                            </span>
                          )}
                          {v.poids && (
                            <span
                              style={{
                                fontSize: '13px',
                                fontWeight: '600',
                                marginBottom: 3
                              }}
                            >
                              <span style={{ color: "grey" }}>{v.poids.old}</span> {'->'} {v.poids.new} kg
                            </span>
                          )}
                          {v.distance && (
                            <span
                              style={{
                                fontSize: '13px',
                                fontWeight: '600',
                                marginBottom: 3
                              }}
                            >
                              <span style={{ color: "grey" }}>{v.distance.old}</span> {'->'} {v.distance.new} km
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  borderTop: 'solid 1px #707070',
                  borderBottom:
                    current.data.training.difficulty !== undefined
                      ? 'solid 1px #707070'
                      : undefined,
                  marginTop: 6,
                  paddingTop: 10,
                  paddingBottom: 10
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}
                >
                  <span style={{ fontSize: '19px', fontWeight: '400' }}>
                    {formatSeconds(current.data.training.doneTime)}
                  </span>
                  <span
                    style={{
                      fontSize: '15px',
                      fontWeight: '400',
                      color: '#9F9F9F'
                    }}
                  >
                    Temps
                  </span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}
                >
                  <span style={{ fontSize: '19px', fontWeight: '400' }}>-</span>
                  <span
                    style={{
                      fontSize: '15px',
                      fontWeight: '400',
                      color: '#9F9F9F'
                    }}
                  >
                    BPM Max
                  </span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}
                >
                  <span style={{ fontSize: '19px', fontWeight: '400' }}>
                    {current.data.training.caloriesBurnt || '-'}
                  </span>
                  <span
                    style={{
                      fontSize: '15px',
                      fontWeight: '400',
                      color: '#9F9F9F'
                    }}
                  >
                    Cal
                  </span>
                </div>
              </div>
              {current.data.training.difficulty !== undefined && (
                <>
                  <span
                    style={{
                      fontSize: '18px',
                      fontWeight: '400',
                      marginTop: '20px'
                    }}
                  >
                    {
                      [
                        "Aucune difficulté choisie",
                        "1 - Très facile",
                        "2 - Facile",
                        "3 - Assez facile",
                        "4 - Peu éprouvant",
                        "5 - Modéré",
                        "6 - Intense",
                        "7 - Assez difficile",
                        "8 - Difficile",
                        "9 - Très difficile",
                        "10 - Trop difficile ",
                      ][current.data.training.difficulty]
                    }
                  </span>
                  <div className="progress-container">
                    <div
                      style={{
                        width: `${((current.data.training.difficulty + 1) /
                          10) *
                          100}%`
                      }}
                    />
                  </div>
                </>
              )}
              {current.data.training.textReview && (
                <div
                  style={{
                    width: '100%',
                    borderTop: 'solid 1px #E0E0E0',
                    marginTop: '10px',
                    paddingTop: '20px',
                    paddingLeft: '30px',
                    paddingRight: '30px',
                    paddingBottom: '20px',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <span style={{ fontSize: '15px', fontWeight: '600' }}>
                    Ressenti
                  </span>
                  <span
                    style={{
                      fontSize: '14px',
                      fontWeight: '400',
                      marginTop: '10px'
                    }}
                  >
                    {current.data.training.textReview}
                  </span>
                </div>
              )}
            </div>
          </div>
        );
      } else if (current.type === 'checkinCall') {
        tempMessages.push(
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '15px'
            }}
          >
            <div
              style={{
                border: 'black 1px solid',
                display: 'flex',
                borderRadius: '15px',
                alignItems: 'center',
                padding: '20px 30px'
              }}
            >
              <img
                src="../static/images/video-camera1.png"
                style={{ width: 35, height: 35, marginRight: '20px' }}
              />
              <div>
                <p
                  style={{
                    fontSize: 10,
                    fontWeight: '700',
                    letterSpacing: 1.5,
                    color: '#A0A0A0'
                  }}
                >
                  {`APPEL VIDÉO PROGRAMMÉ`}
                </p>
                <p
                  style={{
                    fontSize: 15,
                    fontWeight: '700',
                    pTransform: 'capitalize',
                    marginTop: 10,
                    textTransform: 'capitalize'
                  }}
                >
                  {moment(current.data.scheduledDate).calendar()}
                </p>
              </div>
            </div>
          </div>
        );
      } else if (current.type === 'trainingOrder') {
        tempMessages.push(
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '15px'
            }}
          >
            <div
              style={{ padding: '20px 30px', alignItems: "center", justifyContent: "center", textAlign: "center" }}

            >
              <Refresh style={{ width: 20, height: 20, color: "#666666" }} />
              <p
                style={{ fontSize: 12, fontWeight: "400", color: "#666666", textAlign: 'center', padding: 5 }}
              >
                {`${current.data?.name || 'Le client'} a réorganisé ses séances`}
              </p>
            </div>
          </div>
        );
      } else if (current.type === 'subscriptionCancel') {
        tempMessages.push(
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '15px'
            }}
          >
            <div
              style={{
                border: 'black 1px solid',
                display: 'flex',
                borderRadius: '15px',
                alignItems: 'center',
                padding: '20px 30px'
              }}
            >
              <img
                src="../static/images/member-card.png"
                style={{ width: 35, height: 35, marginRight: '20px' }}
              />
              <div>
                <p
                  style={{
                    fontSize: 10,
                    fontWeight: '700',
                    letterSpacing: 1.5,
                    color: '#A0A0A0'
                  }}
                >
                  {`RÉSILIATION`}
                </p>
                <p
                  style={{
                    fontSize: 13,
                    fontWeight: '600',
                    marginTop: 10,
                  }}
                >
                  Fin de l'abonnement le {moment(current.data?.currentPeriodEnd * 1000).format('DD/MM/YYYY')}
                </p>
              </div>
            </div>
          </div>
        );
      } else if (current.type === 'text') {
        let previous = props.messages[i + 1];
        let next = props.messages[i - 1];
        let isMine = current.author === MY_USER_ID;
        let currentMoment = moment(current.createdAt);
        let prevBySameAuthor = false;
        let nextBySameAuthor = false;
        let startsSequence = true;
        let endsSequence = true;
        let showTimestamp = true;

        if (previous) {
          let previousMoment = moment(previous.createdAt);
          let previousDuration = moment.duration(
            currentMoment.diff(previousMoment)
          );
          prevBySameAuthor = previous.author === current.author;

          if (prevBySameAuthor && previousDuration.as('hours') < 1) {
            startsSequence = false;
          }

          if (previousDuration.as('hours') < 1) {
            showTimestamp = false;
          }
        }

        if (next) {
          let nextMoment = moment(next.createdAt);
          let nextDuration = moment.duration(nextMoment.diff(currentMoment));
          nextBySameAuthor = next.author === current.author;

          if (nextBySameAuthor && nextDuration.as('hours') < 1) {
            endsSequence = false;
          }
        }

        tempMessages.push(
          <Message
            key={current?._id || i}
            isMine={isMine}
            startsSequence={startsSequence}
            endsSequence={endsSequence}
            showTimestamp={showTimestamp}
            data={current}
          />
        );
      }

      // Proceed to the next message.
      i += 1;
    }

    return tempMessages;
  };
  const handleInputHeightChange = inputHeight => {
    const messageListContainer = document.getElementById('message-list');
    if (messageListContainer) {
      messageListContainer.style.height = `calc(100% - (${inputHeight}px + 50px))`;
    }
  };

  const handleScroll = e => {
    const el = e.target;
    if (el.scrollHeight - (el.clientHeight + 50) <= el.scrollTop * -1) {
      getMoreMessages();
    }
  };

  return (
    <>
      <div
        id="message-list"
        ref={messageList}
        className="message-list-container"
        onScroll={handleScroll}
      >
        {renderMessages()}
      </div>
      {props.messages && (
        <Compose
          unSelectCustomer={props.unSelectCustomer}
          selectedCustomer={props.selectedCustomer}
          onMessage={onMessage}
          onImage={onImage}
          rightItems={[]}
          onInputHeightChange={handleInputHeightChange}
        />
      )}
    </>
  );
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    messages: state.messaging.messages
  };
};

const mapDispatchToProps = { setMessages, clearNotif, updateStudent };

export default connect(mapStateToProps, mapDispatchToProps)(MessageList);
