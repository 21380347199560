import { combineReducers } from 'redux';
import _ from 'lodash';

function auth(
  state = {
    user: null,
    students: [],
    hidden: [],
    exercices: [],
    progStatus: {},
  },
  action
) {
  switch (action.type) {
    case 'LOGIN': {
      return {
        ...state,
        user: action.user,
        students: action.students,
        hidden: action.hidden
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        user: false,
        students: []
      };
    }
    case 'CLEAR_NOTIF': {
      let newStudents = _.cloneDeep(state.students);
      let student = newStudents.find(v => v._id === action.customerId);

      if (student) {
        if (student.coachNotif === 0) {
          return state;
        }
        student.coachNotif = 0;
      }

      return {
        ...state,
        students: newStudents
      };
    }
    case 'ADD_NOTIF': {
      let newStudents = _.cloneDeep(state.students);
      let student = newStudents.find(v => v._id === action.customerId);

      if (student) {
        if (student.coachNotif > 0) {
          return state;
        }
        student.coachNotif = 1;
      }

      return {
        ...state,
        students: newStudents
      };
    }
    case 'ADD_STUDENT': {
      return {
        ...state,
        students: [action.student, ...state.students]
      };
    }
    case 'UPDATE_STUDENT': {
      let newStudents = _.cloneDeep(state.students);
      let studentIndex = newStudents.findIndex(
        v => v._id === action.student._id
      );

      if (studentIndex >= 0) {
        if (action.student.connected === undefined) {
          action.student.connected = newStudents[studentIndex].connected;
        }
        if (action.student.isTraining === undefined) {
          action.student.isTraining = newStudents[studentIndex].isTraining;
        }
        if (action.student.isTrainingDone === undefined) {
          action.student.isTrainingDone =
            newStudents[studentIndex].isTrainingDone;
        }
        if (action.student.isTrainingMissed === undefined) {
          action.student.isTrainingMissed =
            newStudents[studentIndex].isTrainingMissed;
        }
        if (action.student.hasTraining === undefined) {
          action.student.hasTraining =
            newStudents[studentIndex].hasTraining;
        }
        if (action.student.stats === undefined) {
          action.student.stats =
            newStudents[studentIndex].stats;
        }

        newStudents[studentIndex] = action.student;
      }

      return {
        ...state,
        students: newStudents
      };
    }
    case 'SET_CONNECTED': {
      let newStudents = _.cloneDeep(state.students);
      let studentIndex = newStudents.findIndex(v => v._id === action.id);

      if (studentIndex >= 0) {
        newStudents[studentIndex].connected = !!action.connected;
        if (!action.connected)
          newStudents[studentIndex].lastConnection = new Date();
      }

      return {
        ...state,
        students: newStudents
      };
    }
    case 'SET_IS_TRAINING': {
      let newStudents = _.cloneDeep(state.students);
      let studentIndex = newStudents.findIndex(v => v._id === action.id);

      if (studentIndex >= 0) {
        newStudents[studentIndex].isTraining = !!action.isTraining;
      }

      return {
        ...state,
        students: newStudents
      };
    }
    case 'SET_IS_TRAINING_DONE': {
      let newStudents = _.cloneDeep(state.students);
      let studentIndex = newStudents.findIndex(v => v._id === action.id);

      if (studentIndex >= 0) {
        newStudents[studentIndex].isTrainingDone = !!action.isTrainingDone;

      }

      return {
        ...state,
        students: newStudents
      };
    }
    case 'SET_EXERCICES': {
      return {
        ...state,
        exercices: action.exercices
      };
    }
    case 'SET_PROG_STATUS': {
      return {
        ...state,
        progStatus: action.progStatus
      };
    }
    case 'SHOW_HIDDEN_STUDENTS': {
      console.log('SHOW_HIDDEN_STUDENTS', state.students, state.hidden)
      return {
        ...state,
        students: [...state.students, ...state.hidden],
        hidden: []
      };
    }
    default:
      return state;
  }
}

function messaging(
  state = {
    messages: null,
    customerId: null,
    conversationListOpen: true,
    notifications: []
  },
  action
) {
  switch (action.type) {
    case 'SET_MESSAGES':
      return {
        ...state,
        messages: action.messages,
        customerId: action.customerId
      };
    case 'TOGGLE_CONVERSATION_LIST':
      return {
        ...state,
        conversationListOpen: !state.conversationListOpen
      };
    case 'SET_NOTIFICATIONS':
      return {
        ...state,
        notifications: action.notifications
      };
    case 'DELETE_MESSAGE': {
      return {
        ...state,
        messages: state.messages
          ? state.messages.filter(v => v._id !== action.id)
          : state.messages
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        messages: null,
        customerId: null,
        conversationListOpen: true,
        notifications: []
      };
    }
    default:
      return state;
  }
}

const reducers = combineReducers({
  auth,
  messaging
});

export default reducers;
