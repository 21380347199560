import React from 'react';
import { Avatar, makeStyles } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import DrawerItem from '../DrawerItem';
import FolderIcon from '@material-ui/icons/Folder';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import './Drawer.css';

const useStyles = makeStyles(theme => ({
  root: {
    width: '310px'
  },
  heading: {
    fontSize: theme.typography.pxToRem(13),
    fontWeight: 600,
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 400,
    color: theme.palette.text.secondary
  },
  small: {
    width: theme.spacing(5),
    height: theme.spacing(5)
  }
}));

export default function CustomizedAccordions({
  title,
  value,
  conversations,
  onCustomerSelect,
  selectedCustomer,
  showTraningDate,
  props,
  src
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  var size = conversations?.length ? conversations.length : '0';
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const lastTrainingDoneTime = () => {
    if (
      conversations[0] &&
      showTraningDate &&
      moment(conversations[0].customer.isTrainingDone).fromNow() ===
        'Invalid date'
    )
      return 'Il y a 1 sec';
    return (
      conversations[0] &&
      showTraningDate &&
      moment(conversations[0].customer.isTrainingDone).fromNow()
    );
  };
  return (
    <div className={classes.root}>
      <Accordion
        style={{ paddingLeft: '4px' }}
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary
          style={{ flexDirection: ' row-reverse' }}
          expandIcon={<ExpandMoreIcon style={{ marginRight: '0px' }} />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Avatar className={classes.small} src={null}>
            <img src={src} style={{ width: 20, height: 20 }} />
          </Avatar>
          <div>
            <Typography className={classes.heading}>
              {title} ({size})
            </Typography>
            <Typography className={classes.secondaryHeading}>
              {lastTrainingDoneTime()}
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {value === 0 &&
            conversations.map((conv, i) => {
              //console.log("op",selectedCustomer,conv.customer._id)
              return (
                <DrawerItem
                  key={i}
                  showTraningDate={showTraningDate}
                  selected={selectedCustomer?._id === conv.customer._id}
                  onCustomerSelect={onCustomerSelect}
                  customer={conv.customer}
                  selectedCustomer={selectedCustomer}
                />
              );
            })}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
