import React, { useState, useEffect, useMemo } from "react";
import ConversationSearch from "../ConversationSearch";
import ConversationListItem from "../ConversationListItem";
import CustomizedAccordions from "../Drawer";
import axios from "axios";
import { setNotifications } from "../../../../../korper-redux/actions";
import { connect } from "react-redux";
import {
  makeStyles,
  Tabs,
  Tab,
  Badge,
  TextField,
  InputAdornment,
  SvgIcon,
  Search,
} from "@material-ui/core";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import SearchIcon from "@material-ui/icons/Search";
import moment from "moment";
import { useLocation } from "react-router-dom";
import _ from "lodash";

import "moment/locale/fr.js";
moment.locale("fr");

import "./ConversationList.css";
import { set } from "lodash";
import { TabPanel } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  tabRoot: {
    minWidth: 140,
  },
  badge: {
    height: "16px",
    minWidth: "16px",
    padding: "0 4px",
  },
}));

function ConversationList(props) {
  const [conversations, setConversations] = useState([]);
  const [notifications, setNotifs] = useState([]);
  const [messagesNotifCount, setMessagesNotifCount] = useState(0);
  const [value, setValue] = useState(0);
  const [isSort, setIsSort] = useState(false);
  const [searchCustomer, setSearchCustomer] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const classes = useStyles();

  const conversationTrainingMissed = useMemo(() => {
    return conversations.filter((value) => !!value.customer.isTrainingMissed);
  }, [conversations]);

  const conversationTrainingDone = useMemo(() => {
    return conversations
      .filter((value) => !!value.customer.isTrainingDone)
      .sort((a, b) => {
        return moment(b.customer.isTrainingDone).diff(
          a.customer.isTrainingDone,
          "seconds"
        );
      });
  }, [conversations]);

  useEffect(() => {
    getConversations();
  }, [props.students]);

  useEffect(() => {
    sortTalkCustomer();
  }, [isSort]);

  useEffect(() => {
    getNotifications();
  }, [props.notifications]);

  const search = useLocation().search;

  useEffect(() => {
    const customerId = new URLSearchParams(window.location.search).get(
      "customer"
    );

    if (customerId !== props.selectedCustomer?._id) {
      let foundConversation = conversations?.find(
        (v) => v?.customer?._id === customerId
      );

      if (foundConversation) {
        props.onCustomerSelect(foundConversation.customer);
      }
    }
  }, [search, window.location.search, conversations]);

  const sortTalkCustomer = () => {
    let newConversations = props.students.map((customer) => {
      let time = null;
      let timeWarning = null;

      const lastMessageDate = !!customer.firstUnansweredMessageDate ? customer.firstUnansweredMessageDate : customer.lastMessageDate

      if (lastMessageDate) {
        if (moment(lastMessageDate).diff(moment(), "hours") <= -24) {
          time = undefined;
          timeWarning = moment(lastMessageDate).fromNow();
        } else {
          time = moment(lastMessageDate).fromNow();
          timeWarning = undefined;
        }
      }

      return {
        name: `${customer.firstName} ${customer.lastName}`,
        text: customer.lastMessageText
          ? `${customer.lastMessageText.substring(0, 70)}${customer.lastMessageText.length > 70 ? "..." : ""
          }`
          : "-",
        customer,
        dot: !!customer.coachNotif,
        time,
        timeWarning,
      };
    });
    let notTalkCustomer = newConversations.filter(
      (item) => item.text == "Nouveau client"
    );
    let talkedCustomer = newConversations.filter(
      (item) => item.text !== "Nouveau client"
    );

    notTalkCustomer.sort((a, b) => sortConversationsListPriority(a, b));
    talkedCustomer.sort((a, b) =>
      isSort
        ? sortConversationsListRecent(a, b)
        : sortConversationsListPriority(a, b)
    );

    setConversations(notTalkCustomer.concat(talkedCustomer));
  };

  const getConversations = () => {
    const count = props.students.reduce(
      (total, customer) => total + (customer.coachNotif || 0),
      0
    );
    setMessagesNotifCount(count);
    sortTalkCustomer();
  };

  const deleteNotification = async (id) => {
    try {
      let response = await axios.delete(
        `https://online.korper.io/coachs/notification/${id}`,
        { withCredentials: true }
      );

      if (response.data.success) {
        try {
          let res = await axios.get(
            "https://online.korper.io/coachs/notifications",
            { withCredentials: true }
          );

          if (res.data.success) {
            props.setNotifications(res.data.notifications);
          }
        } catch (err) {
          console.error(err);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getNotifications = () => {
    let newNotifications = props.notifications.map((notification) => {
      let time = null;

      let date = moment(notification.createdAt);

      if (moment().isSame(date, "day")) {
        time = date.format("HH:mm");
      } else {
        time = date.format("DD/MM");
      }

      return {
        name: notification.title,
        text: `${notification.text.substring(0, 70)}${notification.text.length > 70 ? "..." : ""
          }`,
        customer: notification.customer,
        dot: false,
        onDelete: () => deleteNotification(notification._id),
        time,
      };
    });
    setNotifs(newNotifications);
  };

  const sortConversationsListPriority = (a, b) => {
    if (a.customer.coachNotif && !b.customer.coachNotif) {
      return -1;
    }
    if (!a.customer.coachNotif && b.customer.coachNotif) {
      return 1;
    }

    return moment(!!a.customer.firstUnansweredMessageDate ? a.customer.firstUnansweredMessageDate : a.customer.lastMessageDate).diff(
      !!b.customer.firstUnansweredMessageDate ? b.customer.firstUnansweredMessageDate : b.customer.lastMessageDate,
      "seconds"
    );
  };

  const sortConversationsListRecent = (a, b) => {
    return moment(!!b.customer.firstUnansweredMessageDate ? b.customer.firstUnansweredMessageDate : b.customer.lastMessageDate).diff(
      !!a.customer.firstUnansweredMessageDate ? a.customer.firstUnansweredMessageDate : a.customer.lastMessageDate,
      "seconds"
    );
  };

  //Racourci clavier change customer

  useEffect(() => {
    let handler = (event) => {
      if (event.repeat) return;
      if (
        (event.ctrlKey || event.metaKey) &&
        (event.key === "ArrowUp" || event.key === "ArrowDown")
      ) {
        onShortKeySwap(event);
      }
    };
    document.addEventListener("keydown", handler);
    return () => {
      removeEventListener("keydown", handler);
    };
  }, []);

  const onShortKeySwap = (event) => {
    let elementList = [...document.getElementsByClassName("student-item")];
    let selectedCustomerIndex = elementList.findIndex(
      (v) =>
        v?.attributes.class.nodeValue ===
        "conversation-list-item-selected student-item"
    );
    var objDiv = document.getElementById("scrollableListconvestation");

    if (selectedCustomerIndex < props.students.length - 1) {
      if ((event.ctrlKey || event.metaKey) && event.key === "ArrowDown") {
        selectedCustomerIndex++; // Passe au client suivant
        objDiv.scrollTop += 86;
      }
      if ((event.ctrlKey || event.metaKey) && event.key === "ArrowUp") {
        selectedCustomerIndex--;
        objDiv.scrollTop -= 86;
      }
    }

    const cus = props.students.find(
      (v) =>
        v._id ===
        elementList[selectedCustomerIndex]?.attributes["data-id"].nodeValue
    );
    if (cus !== undefined) {
      props.setSelectedCustomer(cus);
      window.history.replaceState(null, "", `?customer=${cus._id}`);
    }
  };

  const filteredSearchCustomer = conversations.filter((v) => {
    let result = true;
    if (searchCustomer) {
      let txt = new RegExp(searchCustomer, "i");
      result = v?.name.match(txt);
    }

    return result;
  });

  return (
    <>
      <div className="conversation-list">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab
            classes={{ root: classes.tabRoot }}
            label={
              <Badge
                badgeContent={messagesNotifCount}
                color="error"
                classes={{ badge: classes.badge }}
              >
                <span style={{ paddingRight: 4, marginTop: -4 }}>
                  Messagerie
                </span>
              </Badge>
            }
          />
          <Tab
            classes={{ root: classes.tabRoot }}
            label={
              <Badge
                badgeContent={notifications.length}
                color="error"
                classes={{ badge: classes.badge }}
              >
                <span style={{ paddingRight: 4, marginTop: -4 }}>
                  Notifications
                </span>
              </Badge>
            }
          />
        </Tabs>

        <div style={{ padding: "10px" }}>
          <div
            style={{
              background: "#f4f4f8",
              padding: "8px 10px",
              borderRadius: "10px",
              border: "none",
              fontSize: "14px",
              display: "grid",
              gridTemplateColumns: "12% 68% 20%",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "24px",
                height: "24px",
                transform: isSort ? " rotate(0deg)" : " rotate(-180deg)",
                display: "inline-block",
                position: "relative",
              }}
            >
              <SwapVertIcon />
              <div
                style={{
                  width: "60%",
                  borderBottomRightRadius: "100%",
                  position: "absolute",
                  top: "0",
                  left: "0",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  color: "#2b96f2",
                }}
              >
                <SwapVertIcon />
              </div>
            </div>

            <div>Voir les plus récents</div>
            <div>
              <button
                style={{
                  width: "50px",
                  height: "25px",
                  borderRadius: "25px",
                  border: "none",
                  backgroundColor: isSort ? "#2b96f2" : "#ddd",
                  position: "relative",
                  transition: "background-color 0.2s",
                }}
                onClick={() => {
                  setIsSort(!isSort);
                }}
                className={isSort ? "active" : ""}
              >
                <span
                  style={{
                    position: "absolute",
                    top: "2.5px",
                    left: "2.5px",
                    width: "20px",
                    height: "20px",
                    borderRadius: "20px",
                    backgroundColor: "#fff",
                    transition: "transform 0.2s",
                    transform: isSort ? "translateX(25px)" : "none",
                  }}
                ></span>
              </button>
            </div>
          </div>
          <div className="searchCustomer" style={{ marginTop: "15px" }}>
            <TextField
              style={{
                marginTop: "1px",
                backgroundColor: "white",
              }}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" style={{ color: "#3888FF" }}>
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              placeholder="Rechercher"
              variant="outlined"
              value={searchCustomer}
              onChange={(e) => [setSearchCustomer(e.target.value)]}
              autoFocus
            />
          </div>
        </div>
        {/* <ConversationSearch conversations={conversations}/> */}
      </div>
      {value === 0 && (
        <>
          <CustomizedAccordions
            showTraningDate
            selectedCustomer={props.selectedCustomer}
            onCustomerSelect={props.onCustomerSelect}
            value={value}
            src={"../static/images/calendar-check.png"}
            conversations={conversationTrainingDone}
            title={"Entraînements complétés"}
          ></CustomizedAccordions>
          <CustomizedAccordions
            selectedCustomer={props.selectedCustomer}
            onCustomerSelect={props.onCustomerSelect}
            value={value}
            src={"../static/images/calendar-cross.png"}
            conversations={conversationTrainingMissed}
            title={"Entraînements manqués hier"}
          ></CustomizedAccordions>
        </>
      )}

      {value === 0 &&
        filteredSearchCustomer.map((conversation) => (
          <ConversationListItem
            onClick={() => props.onCustomerSelect(conversation.customer)}
            key={conversation.customer._id}
            data={conversation}
            unSelectCustomer={props.unSelectCustomer}
            selected={props.selectedCustomer?._id === conversation.customer._id}
          />
        ))}
      {value === 1 &&
        notifications.map((notification, i) => (
          <ConversationListItem
            onClick={() => props.onCustomerSelect(notification.customer)}
            key={i}
            data={notification}
            selected={props.selectedCustomer?._id === notification.customer._id}
          />
        ))}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    students: state.auth.students,
    notifications: state.messaging.notifications,
  };
};

const mapDispatchToProps = { setNotifications };

export default connect(mapStateToProps, mapDispatchToProps)(ConversationList);
