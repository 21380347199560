/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import PlayCircleFilled from "@material-ui/icons/PlayCircleFilled";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
import CancelIcon from "@material-ui/icons/Cancel";
import CreateIcon from "@material-ui/icons/Create";
import "./audioPlayer.css";
import { IconButton } from "@material-ui/core";

const AudioPlayer = (props) => {
  const [playing, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState("0:00");
  const [durationTime, setDurationTime] = useState("0:00");
  const audioRef = useRef(null);

  function Ended() {
    setPlaying(false);
  }

  function toggleAudio() {
    if (audioRef.current?.paused) {
      audioRef.current?.play();
      setPlaying(true);
    } else {
      audioRef.current?.pause();
      setPlaying(false);
    }
    const interval = setInterval(updateCurrentTime, 1000);
    return () => {
      clearInterval(interval);
    };
  } 

  function updateCurrentTime() {
    const minutes = Math.floor(audioRef.current?.currentTime / 60)
      .toString()
      .padStart(1, "0");
    const seconds = Math.floor(audioRef.current?.currentTime % 60)
      .toString()
      .padStart(2, "0");
    setCurrentTime(`${minutes}:${seconds}`);
  }

  return (
    <div
      style={{ background: "red", borderRadius: "15px" }}
      className="audio-player"
    >
      <audio
        ref={audioRef}
        onLoadedMetadata={(e) => {
          if (e.target.duration === Infinity) {
            e.target.currentTime = 1000000;
            setTimeout(() => {
              audioRef.current.currentTime = 0;
            }, 500);
          }
        }}
        onEnded={Ended}
        src={props.src}
      />
      <div className="controls">
        <div
          style={{ marginLeft: "5px", display: "flex", alignItems: "center" }}
          size="small"
        >
          <div class="player-button" onClick={() => toggleAudio()}>
            {playing ? (
              <PauseCircleFilledIcon
                style={{ color: "white" }}
                fontSize="small"
              />
            ) : (
              <PlayCircleFilled style={{ color: "white" }} fontSize="small" />
            )}
          </div>
          {audioRef.current?.duration && !playing ? (
            <p id="current-time">
              0:
              {Math.ceil(audioRef.current.duration - 1)
                .toString()
                .padStart(2, "0")}
            </p>
          ) : (
            <p id="current-time">{currentTime}</p>
          )}
          <IconButton onClick={props.openAudioModal} style={{ padding: 0 }}>
            <img
              src="../static/images/pen.png"
              style={{ width: "12.5px", height: "12.5px", marginLeft: "5px" }}
            />
          </IconButton>
          <CancelIcon
            className="optionAudio"
            style={{ color: "white", marginRight: "5px", marginLeft: "5px" }}
            onClick={props.deleteAudio}
            fontSize="small"
          />
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;
