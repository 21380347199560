import { func } from "prop-types"

export function login(user, students, hidden) {
    return {
        type: 'LOGIN',
        user,
        students,
        hidden
    }
}

export function logout() {
    return {
        type: 'LOGOUT'
    }
}

export function setExercices(exercices) {
    return {
        type: 'SET_EXERCICES',
        exercices
    }
}

export function setProgStatus(progStatus) {
    return {
        type: 'SET_PROG_STATUS',
        progStatus
    }
}

export function setIsTrainingDone(id, isTrainingDone) {
    return {
        type: 'SET_IS_TRAINING_DONE',
        id,
        isTrainingDone
    }
}

export function setMessages(messages, customerId) {
    return {
        type: 'SET_MESSAGES',
        messages,
        customerId
    }
}

export function deleteMessage(id) {
    return {
        type: 'DELETE_MESSAGE',
        id
    }
}

export function clearNotif(customerId) {
    return {
        type: 'CLEAR_NOTIF',
        customerId
    }
}
export function addNotif(customerId) {
    return {
        type: 'ADD_NOTIF',
        customerId
    }
}

export function addStudent(student) {
    return {
        type: 'ADD_STUDENT',
        student
    }
}

export function updateStudent(student) {
    return {
        type: 'UPDATE_STUDENT',
        student
    }
}

export function showHiddenStudents() {
    return {
        type: 'SHOW_HIDDEN_STUDENTS'
    }
}

export function setConnected(id, connected) {
    return {
        type: 'SET_CONNECTED',
        id,
        connected
    }
}

export function setIsTraining(id, isTraining) {
    return {
        type: 'SET_IS_TRAINING',
        id,
        isTraining
    }
}

export function toggleConversationList() {
    return {
        type: 'TOGGLE_CONVERSATION_LIST'
    }
}

export function setNotifications(notifications) {
    return {
        type: 'SET_NOTIFICATIONS',
        notifications
    }
}
