/* eslint-disable */
import React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Container,
  TextField,
  Link,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Page from "../../components/Page";
import Cookies from "js-cookie";
import axios from "axios";
import { connect } from "react-redux";
import {
  login,
  logout,
  setNotifications,
  setExercices,
} from "../../korper-redux/actions";
import { register } from "../../socket";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const LoginView = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const onSubmit = async (v) => {
    try {
      let response = await axios.post("https://online.korper.io/coachs/login", {
        email: v.email,
        password: v.password,
      });

      if (response.data.success) {
        Cookies.set("user_token", response.data.token, {
          expires: 14,
          domain: "korper.io",
        });
        register(response.data.coach._id);
        props.login(response.data.coach, response.data.students, response.data.hidden);

        try {
          let res = await axios.get(
            "https://online.korper.io/coachs/notifications",
            { withCredentials: true }
          );

          if (res.data.success) {
            props.setNotifications(res.data.notifications);
          }
        } catch (err) {
          console.error(err);
        }

        try {
          let res = await axios.get(
            "https://online.korper.io/coachs/exercices",
            { withCredentials: true }
          );

          if (res.data.success) {
            props.setExercices(res.data.exercices);
          }
        } catch (err) {
          console.error(err);
        }
      } else {
        toast.error("Erreur, les identifiants sont invalides");
      }
    } catch (error) {
      console.error(error);
      toast.error("Erreur serveur, connexion impossible");
    }
  };

  return (
    <Page className={classes.root} title="Login">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Must be a valid email")
                .max(255)
                .required("Email is required"),
              password: Yup.string().max(255).required("Password is required"),
            })}
            onSubmit={onSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography color="textPrimary" variant="h2">
                    Connexion
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Espace coach
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Mot de passe"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Connexion
                  </Button>
                </Box>
                <Link component={RouterLink} to="/login" variant="h6">
                  Mot de passe oublié ?
                </Link>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = { login, logout, setNotifications, setExercices };

export default connect(mapStateToProps, mapDispatchToProps)(LoginView);
